<template>
    <modal :id="id" title=" ">
        <template #default>
            <p>
                <v-icon color="error" left>circle-exclamation</v-icon>
                <slot name="warning"></slot>
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="confirm"
            >
                Yes, Continue
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';

const GenerateContentWarningProps = Vue.extend({
    name: 'GenerateContentWarning',
    props: {
        id: {
            type: String,
            required: true
        }
    }
});
@Component({
    components: {
        Modal
    }
})
export default class WarnPortraitDialog extends GenerateContentWarningProps {
    close() {
        this.$store.dispatch('modal/close', this.id);
    }

    confirm() {
        this.$emit('confirm');
        this.close();
    }
}
</script>
