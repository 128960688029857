<template>
    <v-container class="pa-0">
        <v-sheet
            v-for="(line, index) in outline"
            :key="index + '_outline'"
            class="outline-wrap py-2"
        >
            <div class="d-flex flex-row">
                <a-text-input
                    :id="`child-heading-${index}`"
                    v-model="line.heading"
                    class="ma-0 outline-input outline-input--parent"
                    hide-details
                    hide-label
                    rules="required"
                    textarea
                    auto-grow
                    dense
                    solo
                    flat
                    rows="1"
                    @blur="removeIfEmpty(line.heading, index, null)"
                    @input="updateOutline"
                >
                    <template #append>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    :disabled="index === 0"
                                    v-bind="attrs"
                                    class="outline-action move-action"
                                    color="secondary"
                                    x-small
                                    icon
                                    plain
                                    v-on="on"
                                    @click="moveHeading(true, index)"
                                >
                                    <v-icon small>arrow-up</v-icon>
                                </v-btn>
                            </template>
                            <span>Move Up</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    class="outline-action move-action"
                                    color="secondary"
                                    x-small
                                    icon
                                    plain
                                    :disabled="index === outline.length - 1"
                                    v-on="on"
                                    @click="moveHeading(false, index)"
                                >
                                    <v-icon small>arrow-down</v-icon>
                                </v-btn>
                            </template>
                            <span>Move Down</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    v-show="outline.length > 1"
                                    v-bind="attrs"
                                    class="outline-action remove-action pb-1"
                                    color="secondary"
                                    icon
                                    plain
                                    small
                                    v-on="on"
                                    @click="removeChild(index, null)"
                                >
                                    <v-icon small>trash</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove Section</span>
                        </v-tooltip>
                    </template>
                </a-text-input>
            </div>
            <v-container v-if="line.sections" class="flex-row pl-8 pr-0 py-0">
                <v-sheet
                    v-for="(childLine, childIndex) in line.sections"
                    :key="childIndex + '_outline_inner'"
                    class="d-flex pr-0"
                >
                    <a-text-input
                        :id="`child-heading-${index}-${childIndex}`"
                        v-model="childLine.heading"
                        class="ma-0 outline-input"
                        rules="required"
                        hide-label
                        hide-details
                        textarea
                        auto-grow
                        solo
                        flat
                        dense
                        rows="1"
                        @blur="
                            removeIfEmpty(childLine.heading, index, childIndex)
                        "
                        @input="updateOutline"
                    >
                        <template #append>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        class="outline-action move-action"
                                        color="secondary"
                                        x-small
                                        icon
                                        plain
                                        :disabled="childIndex === 0"
                                        v-on="on"
                                        @click="
                                            moveSection(true, index, childIndex)
                                        "
                                    >
                                        <v-icon small>arrow-up</v-icon>
                                    </v-btn>
                                </template>
                                <span>Move Up</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        class="outline-action move-action"
                                        color="secondary"
                                        x-small
                                        icon
                                        plain
                                        :disabled="
                                            childIndex ===
                                            line.sections.length - 1
                                        "
                                        v-on="on"
                                        @click="
                                            moveSection(
                                                false,
                                                index,
                                                childIndex
                                            )
                                        "
                                    >
                                        <v-icon small>arrow-down</v-icon>
                                    </v-btn>
                                </template>
                                <span>Move Down</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        class="outline-action remove-action"
                                        color="secondary"
                                        x-small
                                        icon
                                        plain
                                        v-on="on"
                                        @click="removeChild(index, childIndex)"
                                    >
                                        <v-icon small>trash</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove Heading</span>
                            </v-tooltip>
                        </template>
                    </a-text-input>
                </v-sheet>
            </v-container>
            <v-sheet class="pl-8 pt-2">
                <v-btn
                    class="add-action pb-1"
                    color="secondary"
                    plain
                    small
                    @click="addAndFocus(index)"
                >
                    <v-icon small>plus</v-icon>
                    &nbsp;
                    <span>Add Sub Section</span>
                </v-btn>
            </v-sheet>
        </v-sheet>
        <v-sheet>
            <v-btn
                class="add-action pb-1"
                color="secondary"
                plain
                @click="addAndFocus(null)"
            >
                <v-icon small>plus</v-icon>
                <span>Add Section</span>
            </v-btn>
        </v-sheet>
    </v-container>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';
import { Outline } from '@/types/BlogPost';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

const AOutlineEditorProps = Vue.extend({
    name: 'ATemplateSelector',
    props: {
        value: {
            type: Array as PropType<Outline[]>,
            required: true
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});
@Component({
    components: {
        ATextInput
    }
})
export default class AOutlineEditor extends AOutlineEditorProps {
    outline: Outline[] = [];
    debounceTimer: number | null = null;

    mounted() {
        this.outline = this.value;
    }

    @Watch('value', { deep: true })
    onValueChange() {
        this.outline = this.value;
    }

    updateOutline() {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = window.setTimeout(() => {
            this.$emit('input', [...this.outline]);
        }, 300);
    }

    moveHeading(up: boolean, index: number) {
        const outline = [...this.outline];
        const newIndex = up ? index - 1 : index + 1;
        outline.splice(newIndex, 0, outline.splice(index, 1)[0]);
        this.$emit('input', outline);
        this.focusTextArea(newIndex, null);
    }

    moveSection(up: boolean, index: number, childIndex: number) {
        const outline = [...this.outline];
        const parent = this.outline[index];
        if (!parent.sections) {
            return;
        }
        const newSections = [...parent.sections];
        const newChildIndex = up ? childIndex - 1 : childIndex + 1;
        newSections.splice(
            newChildIndex,
            0,
            newSections.splice(childIndex, 1)[0]
        );
        outline[index] = { ...parent, sections: newSections };
        this.$emit('input', outline);
        this.focusTextArea(index, newChildIndex);
    }

    removeChild(index: number, childIndex: number | null) {
        if (childIndex !== null) {
            this.outline[index].sections!.splice(childIndex, 1)[0];
        } else {
            this.outline.splice(index, 1)[0];
        }
        this.$emit('input', this.outline);
    }

    addNewSection(index: number | null) {
        const outlineSection = {
            heading: '',
            sections: []
        };
        const outline = [...this.outline];

        if (index === null) {
            outline.push(outlineSection);
            this.$emit('input', outline);
            return;
        }

        if (!outline[index].sections) {
            outline[index].sections = [outlineSection];
        } else {
            outline[index].sections.push(outlineSection);
        }
        this.$emit('input', outline);
    }

    focusTextArea(index: number, childIndex: number | null) {
        const refName = `child-heading-${index}${
            childIndex !== null ? `-${childIndex}` : ''
        }`;

        this.$nextTick(() => {
            this.blurOutline();
            setTimeout(() => {
                const textarea = document.getElementById(
                    refName
                ) as HTMLTextAreaElement | null;
                if (textarea) {
                    textarea.focus();
                }
            }, 10);
        });
    }

    blurOutline() {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }

    addAndFocus(index: number | null) {
        this.blurOutline();
        this.addNewSection(index);
        if (index === null) {
            index = this.outline.length - 1;
        }

        this.$nextTick(() => {
            const length = this.outline[index]?.sections?.length;
            const indexToFocusOn = length && length > 0 ? length - 1 : 0;
            this.focusTextArea(index, indexToFocusOn);
        });
    }

    removeIfEmpty(value: string, index: number, childIndex: number | null) {
        if (value.length > 0) {
            return;
        }
        this.removeChild(index, childIndex);
    }
}
</script>

<style lang="scss" scoped>
.outline-input {
    &.outline-input--parent {
        font-weight: bold !important;
    }
    &:focus,
    &:hover,
    &:active,
    &.v-input--is-focused {
        .outline-action {
            opacity: 1;
        }
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(128, 128, 128, 0.1) !important;
            pointer-events: none;
        }
    }
}

.outline-action {
    opacity: 0;
    margin: auto 0.5em;
    &:focus,
    &:hover,
    &:active {
        opacity: 1;
    }
}
</style>
